input[name="token"] {
    outline: none;
    font-size: 2.5rem !important;
    padding-left: 12px;
    letter-spacing: 23px;
    border: 0;
    background-image: linear-gradient(to left, black 80%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 284px;
}

.page-item {
    display: none;
}


input[name="token"] {

    border: none;
    width: 18rem;
    background: repeating-linear-gradient(90deg, #696969, #696969 2rem, transparent 0, transparent 3rem) 0 100%/18rem 2px no-repeat;
    font-size: 9rem;
    letter-spacing: .5rem;

}

input[name="token"] {

    border: none;
    // width: 9ch;
    background: repeating-linear-gradient(90deg, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/9ch 2px no-repeat;
    font-size: 9ch;
    letter-spacing: .5ch;

}





.form-radio {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;

    @include MQ(SM) {
        display: block;
    }
}

.col-form-label {
    display: block;
    margin: 0 0 5px 0;
}

.form-check-label span {
    margin: 5px;
    display: block;
    background: $gray-2;
    padding: 10px 30px;
    border-radius: 20px;

}

.form-group {
    margin: 50px 0;
}


input[type="radio"],
input[type="checkbox"] {
    display: none;
    position: absolute;
    appearance: none;

    &:checked+span {
        background: $color-10;

    }
}

.enableCheckBox[type="checkbox"] {

    display: inline-block !important;
    position: relative !important;

}


.pincode-input-container {
    text-align: center;
    margin: 20px 0;

    .pincode-input-text {
        padding: 0 !important;
        margin: 0 2px;
        text-align: center;
        border: none !important;
        border-bottom: 1px solid #696969 !important;
        background: transparent;
        width: 50px;
        height: 50px;
        font-size: 20px;
    }

    .pincode-input-text:focus {
        outline: none;
        box-shadow: none;
    }
}

// Encuesta Profile Vehiculos

@media screen and (max-width:600px) {
    tr[ref="datagrid-masDeDosVehiculos-row"] td {
        display: block;
        position: relative;
    }

    table[class="table datagrid-table table-bordered"] thead {
        display: none;
    }


    tbody[ref="datagrid-masDeDosVehiculos-tbody"] tr:nth-child(even) {
        background: rgb(250, 249, 250);
    }


    tr[ref="datagrid-masDeDosVehiculos-row"] td:nth-child(1):before,
    tr[ref="datagrid-masDeDosVehiculos-row"] td:nth-child(2):before {
        position: absolute;
        top: 30px;
        left: 18px;
        font-weight: bold;
    }

    tr[ref="datagrid-masDeDosVehiculos-row"] td:nth-child(1):before {
        content: 'Año del vehículo';
    }

    tr[ref="datagrid-masDeDosVehiculos-row"] td:nth-child(2):before {
        content: 'Tipo de Vehículo';
    }




}


i[class="fa fa-times-circle-o"]::before {
    content: '\f00d';
}

button[ref="datagrid-masDeDosVehiculos-removeRow"] {
    background: #ff264c !important;

    &:hover,
    &:active {
        background: darken(#ff264c, 5%) !important;
    }
}

button[ref="datagrid-masDeDosVehiculos-addRow"] {
    background: #99cf2e !important;
    
    &:hover,
    &:active {
        background: darken(#99cf2e, 5%) !important;
    }
}