// @import url('https://fonts.googleapis.com/css?family=Muli:400,900|Oswald:400,700&display=swap');

html,
body {
    overflow-x: hidden;
}

html {

    color: $white !important;
    font-family: 'Muli', sans-serif;
}

.wrapper-bg {
    background: $color-1;
}

body {
    font-size: 22px;
    //color: $white !important;
    counter-reset: conteo;

    @include MQ(SM){
        font-size: 16px;

        p{
            font-size: 16px;
        }
    }
}

.wrapper-bg {
    body {
        color: $white !important;
    }
}

h1,
h2 {
    color: $white;
    font-family: 'Oswald', sans-serif;
    // text-transform: uppercase;
    font-weight: 700;
    // text-shadow: 3px 3px rgba(0, 0, 0, .1);

    @include MQ(MD) {
        text-align: center;
        font-size: 25px !important;
    }

    span {
        font-size: 0.8em;
        font-family: inherit;
        font-weight: 400;
    }

    b {
        display: block;

        @include MQ(MD) {
            display: inline;
        }
    }
}

.text-secondary--no-shadow{
    text-shadow:none;
}

.uk-modal-title--gray{
    color: #263a4b !important;
}


.uk-navbar-nav>li>a {
    color: #fff !important;
}

.intro-text p{
    color: $white;
}