.body{
    background: $white;
    color:$gray-1;
}

#detallesCuenta{
    dl{
        width: 80%;
        // border:1px solid red;
        @include MQ(MD){
            width: 100%;
        }
        
        dt, dd{
            width: 48%;
            display: inline-block;

            @include MQ(MD){
                width: 100%;
                display: block;
                text-align: left;
            }
            
        }

        dt{
            color:$color-2;
            font-weight: bold;
            text-align: right;
            padding-right:20px;
            
            @include MQ(MD){
                text-align: left;
            }
        }

        dd{
            font-weight: normal;
        }
    }
}