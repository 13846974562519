.list-inline {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 60px 0;

    @include MQ(SM) {
        display: block;
        margin: 0 0;
    }

    button {
        padding: 0 40px;
        line-height: 53px;
        margin: 0 5px;

        @include MQ(SM) {
            width: 100%;
            margin: 10px 5px;
        }
    }

}

.btn[ref="button"] {
    padding: 20px 40px;
}

.uk-button,
button,
.btn,
.btn-primary,
.swal2-confirm,
.swal2-cancel
 {
    border-radius: 30px;
    // box-shadow: 4px 7px 0 rgba(0, 0, 0, .1);
    border: none;
    font-family: 'Oswald', sans-serif;
    color: $white;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.1px;
    text-shadow: 1px 3px 0 rgba(0, 0, 0, .1);


    @include MQ(1246px) {
        font-size: 12px;
    }

    @include MQ(SM) {
        font-size: 11px;
        letter-spacing: 0;
        padding: 5px 0px;
    }


    &:hover,
    &:active,
    &:link {
        color: $white;
    }


}



.btn {

    &-primary {
        background: $color-9 !important;

        &:hover {
            background: darken($color-9, 5) !important;
        }
    }

    &-secondary {
        background: $color-4 !important;

        &:hover {
            background: darken($color-4, 5) !important;
        }
    }

    &-tertiary {
        background: $color-6 !important;

        &:hover {
            background: darken($color-6, 5) !important;
        }
    }



}

.btn-wizard-nav-next {
    background: #A3D542 !important;
    background-color: #A3D542 !important;
}

.btn-wizard-nav-next:hover {
    background: #A3D542 !important;
    background-color: #A3D542 !important;
}

.btn-wizard-nav-previous {
    background: #ff264c !important;
    background-color: #ff264c !important;
}

.btn-wizard-nav-previous:hover {
    background: #ff264c !important;
    background-color: #ff264c !important;
}

.btn-wizard-nav-submit {
    background: #28a745 !important;
    background-color: #28a745 !important;
}

.btn-wizard-nav-submit:hover {
    background: #28a745 !important;
    background-color: #28a745 !important;
}

.swal2-close{
    box-shadow: none;
}

.swal2-confirm,
.swal2-cancel{
    font-weight: 300;
    border-radius: 30px !important;
}

.swal2-confirm{
    background: $color-4 !important;
    border: none;
    &:hover {
            background: darken($color-4, 5) !important;
        }
}

.swal2-cancel{
    background: none !important;
    border: 3px solid $color-9 !important;
    color: $color-9 !important;

    &:hover {
            background: darken($color-9, 5) !important;
            color: $white !important;
        }
    
}


button:active, button:focus{
    border: none !important;
    box-shadow: none !important;
}