@import 'variables/vars';
@import 'config/breakpoints';
@import 'config/base';
@import 'components/buttons';
@import 'components/forms';
@import 'components/modals';
@import 'layout/header';
@import 'layout/dashboard/body';
@import 'layout/dashboard/questions';

@import 'layout/admin/header';


.mobile-menu a{

  font-size:12px !important;
  text-shadow: none !important;
}
.closeSession{
  transform: translate(0px, 16px) !important;
  }

  