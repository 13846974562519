.profile-info{
    background: #2d3748;
}

.nav-h2-sidebar {
    color: #64748b!important;
    font-size: .98rem;
    font-weight: 600;
}

.nav-top{
    background: #4a5568;
}

.nav-sidebar{
    background: #f7fafc;
}