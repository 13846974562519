.divinputStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    margin: 0 auto;
}

.labelinputStyle {
    color: #000;
    font-size: 20px;
    letter-spacing: normal;
    line-height: 1.4;
    text-align: center;
    overflow-wrap: break-word;
}

.inputStyle {
    background: none;
    border: none;
    font-family: monospace,monospace;
    font-size: 48px;
    letter-spacing: 13px;
    line-height: 48px;
    outline: none;
    width: inherit;
}

.divProgress {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.divInputStyleProgress {
    background-image: -webkit-gradient(linear,left top,right top,to(#b3b3b3));
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(235,235,235,1) 35%, rgba(0,212,255,1) 100%);  */
    background-repeat: no-repeat;
    background-size: 30px 2px;
    color: transparent;
    display: block;
    position: relative;
    width: 42px;
}