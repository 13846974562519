// breakpoints
   
$XS:     480px;   
$SM:     768px-1;     
$MD:     992px-1; 
$LG:     1200px;

// media queries

@mixin MQ($canvas) {
  @if $canvas == XS {
   @media only screen and (max-width: $XS) { @content; } 
  }

  @else if $canvas == SM {
   @media only screen and (max-width: $SM) { @content; } 
  }

  @else if $canvas == MD {
   @media only screen and (max-width: $MD) { @content; } 
  }

  @else if $canvas == LG {
   @media only screen and (max-width: $LG) { @content; } 
  }

  @else {
   @media only screen and (max-width: $canvas) { @content; } 
  }
}

@mixin MQH($canvas) {
  @if $canvas == XS {
   @media only screen and (max-height: $canvas) { @content; } 
  }
}