
//Brand Colors
$color-1 : #03BCD6;
$color-2 : #32C9DE;
$color-3 : #FF3F61;
$color-4 : #A3D542;
$color-5 : #3E4EB8;
$color-6 : #F3A31D;
$color-7 : #FFED17;
$color-8 : #33c9de;
$color-9 : #ff264c;
$color-10: #DBFBFF;
$color-11: #2696ad;
$white   : #ffffff;
$gray-1  : #5C5C5C;
$gray-2  : #EFEFEF;
$gray-3  : #D3D3D3;

$color-12 : darken($color-2,10);
